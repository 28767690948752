import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { formatNumber, formatUSD } from '@shared/lib/numbers';
import Checkbox from '@shared/ui/inputs/Checkbox';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { ContainerRow } from '@components/styled';
import Button from '@shared/ui/buttons/Button';
import SelectButton from '@shared/ui/buttons/SelectButton';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { BodyMedium } from '@components/styled/Typography';
import { StepFormContainer, StepText, Title, } from '@widgets/sidebars/shared';
import { useWizard } from '@shared/ui/misc/Wizard';
import { ReactComponent as BinanceIcon } from '@icons/exchanges/binance.svg';
import RecieveAmountBadge from '../../RecieveAmountBadge';
import { BalanceManagementSteps, } from '../../../types';
const AssetContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.s,
}));
const IconTextStyled = styled(IconText)(props => ({
    width: 70,
    '& .MuiTypography-root': {
        fontSize: 16,
        color: props.theme.palette.text.primary,
        fontWeight: 500,
    },
}));
const CheckboxContainer = styled.div(props => ({
    width: '100%',
    padding: `${props.theme.spacing_sizes.xs * 1.75}px ${props.theme.spacing_sizes.xs * 1.5}px`,
    marginBottom: props.theme.spacing_sizes.xs * 1.25,
}));
const SelectContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.theme.spacing_sizes.s * 10,
    boxSizing: 'border-box',
}));
const AssetAmount = styled(BodyMedium)(props => ({
    color: props.theme.customColors.input.borderHover,
    letterSpacing: '-0.176px',
}));
const UsdAmount = styled(BodyMedium)(props => ({
    color: props.theme.palette.text.primary,
    letterSpacing: '-0.176px',
}));
const AssetButtonInner = ({ assetIcon, text, amount, usdAmount, }) => (_jsxs(ContainerRow, { children: [_jsxs(AssetContainer, { children: [_jsx(IconTextStyled, { IconComponent: (_jsx(Icon, { IconComponent: assetIcon, keepOriginalColor: true })), text: text }), _jsx(AssetAmount, { children: formatNumber(amount, { maximumFractionDigits: 6 }) })] }), _jsx(UsdAmount, { children: formatUSD(usdAmount) })] }));
const ConvertAssets = ({ exchangeWallet, onConvertAssetsFormSubmit, onSidebarClose, }) => {
    const { t } = useTranslation();
    const { goTo } = useWizard();
    const { setValue, handleSubmit, watch, } = useForm({
        defaultValues: {
            selectedAssets: [],
        },
    });
    const selectedAssets = watch('selectedAssets');
    const assets = useMemo(() => exchangeWallet.assets.filter(asset => asset.amountUSDT > 0), [exchangeWallet]);
    const allAssetsSelected = assets.length === selectedAssets.length;
    // eslint-disable-next-line arrow-body-style
    const totalAmount = useMemo(() => {
        const amountSum = selectedAssets
            .reduce((sum, assetName) => {
            const assetFound = assets.find(asset => asset.asset === assetName);
            return sum + ((assetFound === null || assetFound === void 0 ? void 0 : assetFound.amountUSDT) || 0);
        }, 0);
        return amountSum;
    }, [
        selectedAssets,
        assets,
    ]);
    const onSubmit = (values) => {
        onConvertAssetsFormSubmit(values);
        goTo(BalanceManagementSteps.CalculationProcess);
    };
    const goToPrevStep = () => {
        goTo(BalanceManagementSteps.ChoosePaymentMethod);
    };
    const handleCheckboxClick = (_, checked) => {
        if (checked) {
            const assetNames = assets.map((asset) => asset.asset);
            setValue('selectedAssets', assetNames);
        }
        else {
            setValue('selectedAssets', []);
        }
    };
    const handleAssetSelect = (selectedAsset) => {
        // TODO: check SelectButton, in type declaration its either singular string or an array,
        // but in my quick testing it always return me an array even if only one value was selected
        if (!(typeof selectedAsset === 'object')) {
            return;
        }
        setValue('selectedAssets', selectedAsset);
    };
    return (_jsx(SidebarInner, { title: (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('overview.balance_management.back_btn') })), padding: '50px 30px', cancelBtnProps: {
            onClick: onSidebarClose,
        }, displayCancelBtn: true, children: _jsx(StepFormContainer, { onSubmit: handleSubmit(onSubmit), title: _jsx(Title, { children: t('overview.balance_management.convert_assets.title') }), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { stepNumber: t('overview.balance_management.convert_assets.step'), text: t('overview.balance_management.convert_assets.text') }), _jsx(CheckboxContainer, { children: _jsx(Checkbox, { label: t('overview.balance_management.convert_assets.select_all'), onChange: handleCheckboxClick, checked: allAssetsSelected }) }), _jsx(SelectContainer, { children: _jsx(SelectButton, { options: assets.map((asset) => ({
                                value: asset.asset,
                                element: (_jsx(AssetButtonInner, { assetIcon: BinanceIcon, text: asset.asset, amount: asset.amount, usdAmount: asset.amountUSD })),
                            })), variant: 'with-checkox', onChange: handleAssetSelect, value: selectedAssets, fullWidth: true, multiple: true }) }), _jsx(RecieveAmountBadge, { amount: totalAmount })] })), contentAlign: 'start', contentJustify: 'start', contentFlex: 1, containerGap: 60, buttons: (_jsx(Button, { size: 'large', fullWidth: true, disabled: totalAmount < 5, type: 'submit', children: t('overview.balance_management.convert_assets.continue_btn') })) }) }));
};
export default ConvertAssets;
